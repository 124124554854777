import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css'; // Import the CSS for styling
import GitHubLogo from '../assets/github-logo.png';
import LinkedInLogo from '../assets/LI-In-Bug.png';
import XLogo from '../assets/logo-black.png';
import logo from '../assets/logo-white-large.png';

const Header: React.FC = () => {
    const [isNavVisible, setIsNavVisible] = useState(false);

    return (
        <header className="header">
            <div className="logo">
                <Link to="/">
                    <img src={logo} alt="ht"/>

                </Link>
            </div>
            <button className="menu-icon" onClick={() => setIsNavVisible(!isNavVisible)}>
                &#9776; {/* Unicode character for hamburger icon */}
            </button>
            <nav className={`navigation ${isNavVisible ? 'open' : ''}`}>
                <ul>
                    <li><Link to="/">home</Link></li>
                    <li><Link to="/blog">blog</Link></li>
                    <li><a href="https://github.com/ht7770" target="_blank" rel="noopener noreferrer"><img src={GitHubLogo} alt="GitHub"/></a></li>
                    <li><a href="https://www.linkedin.com/in/henry-thomas-6956a521b" target="_blank" rel="noopener noreferrer"><img src={LinkedInLogo} alt="LinkedIn"/></a></li>
                    <li><a href="https://x.com/henrytxyz" target="_blank" rel="noopener noreferrer"><img src={XLogo} alt="X"/></a></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;