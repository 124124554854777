import React, { useState } from 'react';
import '../styles/global.css';
import { BlogPost } from "../types/BlogPost";
import postsData from "../posts/posts.json";

function extractText(html: string | undefined, wordLimit: number): string {
    if (!html) return "";
    const strippedString = html.replace(/<[^>]+>/g, '');
    return strippedString.split(/\s+/).slice(0, wordLimit).join(' ') + '...';
}

const Blog: React.FC = () => {
    const [selectedPost, setSelectedPost] = useState<BlogPost | null>(null);

    const handlePostClick = (post: BlogPost) => setSelectedPost(post);

    const handleBackClick = () => setSelectedPost(null);

    // Ensure postsData is treated as an array of BlogPost
    const posts: BlogPost[] = Array.isArray(postsData) ? postsData : [];

    return (
        <main>
            {selectedPost ? (
                <article>
                    <button onClick={handleBackClick} className="back-button">
                        ← blog
                    </button>
                    <div className="title-container">
                        <h1 className="post-title">
                            {selectedPost.title}
                        </h1>
                    </div>
                    <p style={{marginTop: '0', marginBottom: '1em'}}>{selectedPost.date}</p>
                    <div dangerouslySetInnerHTML={{__html: selectedPost.content}}/>
                </article>
            ) : (
                <div>
                    <h1>blog</h1>
                    {posts.length === 0 ? (
                        <p>nothing here yet</p>
                    ) : (
                        <div>
                            {posts.map(post => (
                                <div key={post.id} onClick={() => handlePostClick(post)} style={{cursor: 'pointer'}}>
                                    <h2>{post.title}</h2>
                                    <p>{post.date}</p>
                                    <p>{extractText(post.content, 20)}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </main>
    );
};

export default Blog;