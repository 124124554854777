import React from 'react';
import '../styles/footer.css'
import logo from "../assets/logo-blue-small.png";

const Footer: React.FC = () => (
    <footer className="footer">
        <div className="logo">
            <img src={logo} alt="Logo"/>
        </div>
    </footer>
);

export default Footer;