import React from 'react';

const TechBadges = () => {
    const badges = [
        "https://img.shields.io/badge/AWS-%23FF9900.svg?style=for-the-badge&logo=amazon-web-services&logoColor=white",
        "https://img.shields.io/badge/Python-3776AB?style=for-the-badge&logo=python&logoColor=white",
        "https://img.shields.io/badge/Golang-00ADD8?style=for-the-badge&logo=go&logoColor=white",
        "https://img.shields.io/badge/Docker-2496ED?style=for-the-badge&logo=docker&logoColor=white",
        "https://img.shields.io/badge/GitHub_Actions-2088FF?style=for-the-badge&logo=github-actions&logoColor=white",
        "https://img.shields.io/badge/Terraform-623CE4?style=for-the-badge&logo=terraform&logoColor=white",
        "https://img.shields.io/badge/Swift-FA7343?style=for-the-badge&logo=swift&logoColor=white",
        "https://img.shields.io/badge/SQL-00000F?style=for-the-badge&logo=postgresql&logoColor=white"
    ];

    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
            {badges.map(badge => (
                <img key={badge} src={badge} alt="Technology Badge" style={{ margin: '5px' }} />
            ))}
        </div>
    );
};

export default TechBadges;