import React from 'react';
import '../styles/global.css'
import TechBadges from "../components/techbadges";

const Home: React.FC = () => (
    <main>
        <h1>welcome to my site</h1>
        <p>I love cloud, devops & AI</p>
        <p>have a look at some of my posts where I talk about some of the things that I've been working on</p>
        <p>- henry</p>
        <img src="http://ghchart.rshah.org/ht7770" alt="GitHub Chart" className="github-contribution-chart"/>
        <div className="tech-badge-container">
            <TechBadges/>
        </div>
    </main>
);

export default Home;